import _ from 'lodash';

function changeurl(url, title) {
    var new_url = '/' + url;
    window.history.pushState('data', 'Title', new_url);
    document.title = title;
}

// Scroll-down trigger
var $box = $(".scroll-down-area, .scroll-down-button");
$box.css({cursor:'pointer'});
$box.click(function() { /*select class that triggers scroll*/
    $('html, body').animate({
        scrollTop: $(".content").offset().top /*class you want to scroll to!!*/
    }, 500); /*animation time length*/
});

// Hide divs on pageload
$('.jqueryhide').each(function(i, obj) {
    $(this).css("display", "none");
});

// Scroll to anchor
function scrolltoanchor(a_name){
  // Get element
  var $a_element = $('a[name='+a_name+']');
  // show the parent div
  $a_element.closest('div.jqueryhide').css("display", "block");
  // scroll
  $('html, body').animate({
    scrollTop: $a_element.offset().top /*class you want to scroll to!!*/
  }, 500); /*animation time length*/
}

// Scroll-down smoothly when clicking navigation button
$(".navigation li a").click(function() { /* select class that triggers scroll*/
    var $a_href = $(this).attr('href');   /* get href                         */
    var $a_name = $a_href.substring(1,$a_href.length);
    scrolltoanchor($a_name);
});

// Scroll-up trigger
var $box = $("#logo");
$box.css({cursor:'pointer'});
$("#logo").click(function() { /*select class that triggers scroll*/
    $('html, body').animate({
        scrollTop: $("body").offset().top /*class you want to scroll to!!*/
    }, 500); /*animation time length*/
    changeurl('', document.title)
});

// Scale logo when scrolling
//var $window = $(window);
//$window.scroll(function() {
//  var scrollTop = $window.scrollTop();
//  console.log(scrollTop);
//  var $box = $("#logo");
//  if (scrollTop >= 0 && scrollTop < 400) {
//    $box.css({height: 'calc(50vh - '+scrollTop+'px)'});
//  } else if(scrollTop >= 400) {
//  }
//});

// add_email
var user = 'kontakt',
    domain = 'bloomersband.de',
    element = document.getElementById('email');
element.innerHTML = '<a href="mai' + 'lto:' + user + '@' + domain + '">' + user + '@' + domain + '</a>';

// add phone
var numberanfang = '0152', numberende = '24172799',
    element = document.getElementById('phone');
element.innerHTML = numberanfang+numberende;


// Scroll to anchor when fragmet identifier in url
if(window.location.hash) {
    var $hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
    scrolltoanchor($hash);
}
